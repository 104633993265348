import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';


$(document).foundation();


$("recaptcha_form").submit(function (event) {

    var recaptcha = $("g-recaptcha").val();
    if (recaptcha === "") {
        event.preventDefault();
        alert("Please check the recaptcha");
    }
});

// Contact Form
// closes the panel on click outside
$(document).mouseup(function (e) {
    var container = $('#contact-panel');
    if (!container.is(e.target) // if the target of the click isn't the container...
        &&
        container.has(e.target).length === 0) // ... nor a descendant of the container
    {
        container.removeClass('is-active');
    }
});

$(document).mouseup(function (e) {
    var container = $('#contact-panel');
    if (document.getElementById('cancel-button').clicked == true) {
        container.removeClass('is-active');
    }
});






// Sponsors Text Change On Hover
$(function () {
    var oldtext;
    $('#rsei').hover(function () {
        oldtext = $('#partners_para').html();
        $('#partners_para').text("We are sponsored by Rhino Services and cannot thank them enough for their contributions towards CRASH. Rhino Services is an industrial and environmental cleaning contractor serving the metropolitan Atlanta area. Their mission is to create relationships, trust, and opportunities through hard work and integrity of service.");
    }, function () {
        $('#partners_para').html(oldtext)
    });
})


// Sponsors Text Change on Select for Mobile ???????
$(function () {
    var oldtext;
    $('#rsei').click(function () {
        oldtext = $('#partners_para').html();
        $('#partners_para').text("We are sponsored by Rhino Services and cannot thank them enough for their contributions towards CRASH. Rhino Services is an industrial and environmental cleaning contractor serving the metropolitan Atlanta area. Their mission is to create relationships, trust, and opportunities through hard work and integrity of service.");
    }, function () {
        $('#partners_para').html(oldtext)
    });
})


// Sponsors Text Change On Hover
$(function () {
    var oldtext;
    $('#momma').hover(function () {
        oldtext = $('#partners_para').html();
        $('#partners_para').text("Rhino Momma Project is a NGO in Namibia that works to breed white rhinos with the intention to repopulate southern Africa with this magnificent species. They are also part of the custodial program with the Namibian government, housing black rhinos on their land allowing them to also hopefully breed and increase numbers. We have partnered with Rhino Momma to assist with their projects and be able to work together to assist in the future of rhino conservation with the sharing of knowledge, information, and help. We are excited about this partnership and cannot wait to see what the future holds.");
    }, function () {
        $('#partners_para').html(oldtext)
    });
})


// Sponsors Text Change on Select for Mobile ???????
$(function () {
    var oldtext;
    $('#momma').click(function () {
        oldtext = $('#partners_para').html();
        $('#partners_para').text("Rhino Momma Project is a NGO in Namibia that works to breed white rhinos with the intention to repopulate southern Africa with this magnificent species. They are also part of the custodial program with the Namibian government, housing black rhinos on their land allowing them to also hopefully breed and increase numbers. We have partnered with Rhino Momma to assist with their projects and be able to work together to assist in the future of rhino conservation with the sharing of knowledge, information, and help. We are excited about this partnership and cannot wait to see what the future holds.");
    }, function () {
        $('#partners_para').html(oldtext)
    });
})

// About Team Overlay 


$(".js-open-modal").click(function () {
    $(".modal").addClass("visible");
});

$(".js-close-modal").click(function () {
    $(".modal").removeClass("visible");
});

$(document).click(function (event) {
    //if you click on anything except the modal itself or the "open modal" link, close the modal
    if (!$(event.target).closest(".modal,.js-open-modal").length) {
        $("body").find(".modal").removeClass("visible");
    }
});



